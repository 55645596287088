/*
 * @Author: zuoyuxing001
 * @Date: 2024-01-02 15:35:58
 * @LastEditors: zuoyuxing001
 * @LastEditTime: 2024-01-07 18:52:11
 * @FilePath: /popai/src/i18n/en/index.ts
 * @Description: 
 */
/*
 * @Author: zuoyuxing001
 * @Date: 2024-01-02 15:35:58
 * @LastEditors: zuoyuxing001
 * @LastEditTime: 2024-01-02 19:34:35
 * @FilePath: /popai/src/i18n/en/en.ts
 * @Description: 
 */
import layout from './layout.json';
import pages from './pages.json';
import components from './components.json';
import common from './common.json';
import hoc from './hoc.json';
import enums from './ENUM.json';
import terms from './terms';

// 替换 enum.json 插值
import {
  basicChatModelName,
  basicChatModelNameOld,
  proChatModelName,
  gpt4oName,
} from '@/common/model';
import type { kvMap } from '@/type';
const constant = {
  basicChatModelName,
  basicChatModelNameOld,
  proChatModelName,
  gpt4oName,
}
// TODO: 找不到 enum 用在哪些地方，只能导出时替换
export const replaceENUMInterpolation = (enumObj: kvMap<string>) => {
  const result: {
    [key: string]: string;
  } = {}
  Object.entries(enumObj).map(([k, v]) => {
    const value = Object.entries(constant).reduce((acc, [k, v]) => {
      return acc.replace(new RegExp(`{{${k}}}`, 'g'), v)
    }, v)
    result[k] = value
  })
  return result
}


export default {
  translation: {
    layout,
    pages,
    components,
    common,
    hoc,
    ENUM: replaceENUMInterpolation(enums),
    terms
  },
};
